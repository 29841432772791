<template>
    <!-- Profil -->
    <section class="kp_z-index  kp_changed__id  kp_profil  kp_item__window  kp_element--action--close"  id="kp_profil">
            <div class="kp_element--title">
                <div class="kp_element--icon-title">
                    <p class="kp_element--enable">
                       <div class="kp_ascii--icn"><img class="kp_icon_zone--img" src="/images/text.png" alt="reduct"></div>
                       <span>Kévin Prévost - Mon Profil</span>
                    </p>
                </div>
                <div class="kp_element--incons-zone">
                    <div class="kp_icon_zone  kp_icon_zone--reduct  kp_icon--reduct-profil" id="kp_action--reduct--profil"><img class="kp_icon_zone--img" src="/images/reduct_icn.svg" alt="reduct"></div>
                    <div class="kp_icon_zone  kp_icon_zone--resize  kp_icon--resize-profil" id="kp_action--resize--profil"><img class="kp_icon_zone--img" src="/images/resize_icn.svg" alt="resize"></div>
                    <div class="kp_icon_zone  kp_icon_zone--close   kp_icon--close-profil"  id="kp_action--close--profil"><img class="kp_icon_zone--img" src="/images/close_icn.svg" alt="close"></div>
                </div>
            </div>
            <div class="kp_element_container--part-content  kp_element--enable  kp_element__profil--container">
                <div class="kp_element__main-content">
                    <div class="kp_element_zone__profil--left">
                        
                        <div class="kp_menu__barre-etat--photo-container  kp_menu__barre-etat--photo-container-profil">
                            <img class="kp_menu__barre-etat--photo  kp_menu__barre-etat--photo-profil" src="/images/kevinprevost.jpg" alt="Kévin Prévost">
                        </div>
                        <p class="kp_title--ssmenu-profil">xxx</p>
                    </div>
                    <div class="kp_element_zone__profil--right">
                        <p class="kp_title--ssmenu-profil">xxx</p>

                    </div>
                </div>
                
                
            </div>
          
            
            <div class="resize-handle"></div>
        </section>
        <!-- Profil -->
</template>

<script>
export default {
    name: 'Personnaliser',
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
    },
};
</script>

<style scoped>
</style>