<template>
    
</template>

<script>
export default {
    name: 'Browser',
}
</script>

<style scoped>
</style>