<template>
     <!-- Texte image -->
  <section class="kp_image_ascii  kp_z-index  kp_changed__id  kp_text  kp_item__window"  id="kp_text">
            <div class="kp_element--title">
                <div class="kp_element--icon-title">
                    <p class="kp_element--title-p   kp_element--enable">
                       <div class="kp_element_title--icn"><img class="kp_icon_zone--img" src="/images/text.png" alt="reduct"></div><span>Kévin Prévost - autoportrait.txt</span>
                    </p>
                </div>
                <div class="kp_element--incons-zone">
                    <div class="kp_icon_zone  kp_icon_zone--reduct  kp_icon--reduct-text" id="kp_action--reduct--text"><img class="kp_icon_zone--img" src="/images/reduct_icn.svg" alt="reduct"></div>
                    <div class="kp_icon_zone  kp_icon_zone--resize  kp_icon--resize-text" id="kp_action--resize--text"><img class="kp_icon_zone--img" src="/images/resize_icn.svg" alt="resize"></div>
                    <div class="kp_icon_zone  kp_icon_zone--close   kp_icon--close-text"  id="kp_action--close--text"><img class="kp_icon_zone--img" src="/images/close_icn.svg" alt="close"></div>
                </div>
            </div>
                <pre class="kp_image_ascii--content  kp_element--enable">                                                                                                     
                                            .=#@@@@@@#=.                                             
                                        .@@@@@@@@@@@@@@@@@.                                          
                                    .@@@@@@@@@@@@@@@@@@@@@@@@.                                       
                                  #@@@@@@@@%%%############@@@@@:                                     
                                 @@@@@%###******************%@@@.                                    
                                @@@#****++++++***+**+++*+*****###                                    
                               @@****++++++++++++++++++++++++***##                                   
                             .@#**+++++++++++++++++++++++==++++*+*                                   
                             =#++++++++======+++++++===========++++                                  
                             **++++++++++===+++++===========---=+*=.                                 
                             **+++++++++++++++=====-========-----==-                                 
                             +**+++++=============*####**+++=-----+=                                 
                             =**++++============++*###*++++==--:::=+=                                
                             ***+++++**+++====-===+*##**+==#=--:::-== =:=                            
                             ##*++#%%%%%#*++=----=+##*@@@=**=----::-*-::+:                           
                             -#*#%#*###%##*++=---++**=##%-=------::::---:::                          
                             +%%**#**#@####*+==--==+***+==-------:::.-=-:-.                          
                              *#*%#*%#@@#***++=-:---=+====------:::...-==:.                          
                              ****#****#****++=-:::::---------::::...:-=---                          
                              +++++++++**++**+=---::::--------::::...:::::.                          
                              *+++++++++++++*++=---:::------::::::..:-::::                           
                              +*++++++++++++++==--:::-=----:::::::..:-:++                            
                               *+========+++++==---@=:--=--::::::::.----                             
                               ++========+++##*+=:-:--------::::::::--:.                             
                               .*+======++++*%##*****#*##+=--::::::-:--                              
                                +++=====+#%@@@%%@%%##******++=-:::--::=                              
                                 +*+++++#%%%%**++=+=====+*##*=-:::----=                              
                                 =#*+++*##%#%%##*++=---::-=++=------:--                              
                                  =#**+*#%*+*+*++==--::-:---=*+-=--+=--                              
                                   %%***##**********=---:-++++*+**=++=:                              
                                   +@%##%@%#+++++++=-----=+######**+==..                             
                                    +@@@@@@##*+++==+----=+=+####%#*++:...                            
                                     @@@@@@@%###*+=+++==+++*#****+++:.....-                          
                                    **@@@@@@@%%##*+*#*++****###*+++:::.....##@@%.                    
                                   @@@@@@@@@@@@@#%%%%##**##**%%*+-::.......##%%%#*####.              
                               .@@@@@@@@@*@@@@@%@@%@#%%#%#**##+::::::.....:%#%%%###%%%%%%##+##%*.    
                        .=@@@@@@@@@@@@@@@*+++%@@%@@%@@@%%#+-:::::::::.....#%%%%%####%%%%%%#*#%%%#*###
                 =%@@@@@@@@@@@@@@@@@@@@@@**+++====++++===--:::::::::....:-%%@@%%%##%%%%%%#*##%%%%%###
             @@@@@@@@@@@@@@@@@@@@@@@@@@@@@++++====------::::::::::.....::#%%@@########%@%####%%%%%%%#
           @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*+++===----:-:::::::::::..:..:*%%@@#########%@%######%%%%%%
        =@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*==++==---::::::::::::....:::=%%@@%###%%####%@#*######%%%%%
     *%%@%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@=-=====---:-:::::::::::::::-*%%@@###%%#####%@########%#%%%
  @%%%%@@@@@%%@@@@@@@@@@@@@@@@@@@@@%%@@@@@+-----------:::::::::::::::+%@@@@###@@####%%@##########%%%%
@@@%%%%%@%@@%%@@@@@%%@@@@@@%@@@@@@@%%@@@@@@=-------:::::::::::::::::+%@@@@%###@@####%%@**########%##%
%@@%%%%@@%@@%%@@@@%%@@@@@@%%@@@@@@@%#%@@%@@@+----:-:::::::::::::::-*%%@@@@###%@%####%%@*############%
%%@@%%%@@%@@%%%%%%#@@%@@@%#%%%%@@@@%##@@%%%@@%----:::::::-:::::::-@%@@@@@@##%%@%%###%@%*#######*####%
%%%@%%%%@%@@@%%%%#@@%%@@@#%%%%%%@@@###%@@%#@@@@+-:--:::::-:::::-*%@@@@@@@%*%%%@%%%###@#**####***#**#%
%%%%%%%%@@%@%%%##@@%%@@@##%%%##%@@@####@@@%%%%@@@*--:-::---:--+%@@@@@@@@@#*%#%%%%###%%#*####****#***%
%%@%%%%%@@#@%%%#@@#%%%%##%%%#%%#%@@##%#%@@@%#%%%@@%#:---:---*%%%%@@@%@@@@*#%%#%%%%##%%#*####****#***%
#%%@%%%%@@@@%##@@#%%%%%#####%###%@@#####%@@@%%%@%%%%%%=--+@@@@@@@@@#%@@@#*#%##%%%%##%%**#####**#****%
%#%%@@%#%@@@##%@%%%%%%##%%######%@%###%#%%@@@@%%%%#%%%%%%%%@@@@@@@#%@@@%###%##%%%%%%%%**#####**#****%</pre>
            
            <div class="resize-handle"></div>
        </section>
        <!-- Texte image -->
</template>

<script>
    export default {
        name: 'Autoportrait',
    }

</script>

<style scoped>

</style>